import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    auth: null,
    isPasswordForm: null,
    tipoTareas: null,
    tipoActividades: null,
    tipoProyectos: null,
    tipoEsquemas: null,
    tipoEstados: null,
    datosAdministrativos: null,
    clientes: null,
    tecnicos: null,
    snackbar: {
      status: null,
      type: null,
      message: null,
    }
  },
  mutations: {
    _AUTH(state, val) {
      state.auth = val
    },
    _IS_PASSWORD_FORM(state, val) {
      state.isPasswordForm = val
    },
    _SNACKBAR(state, val) {
      state.snackbar = val
    },
    _GET_TIPO_TAREAS(state, val) {
      state.tipoTareas = val
    },
    _GET_TIPO_ACTIVIDADES(state, val) {
      state.tipoActividades = val
    },
    _GET_TIPO_PROYECTOS(state, val) {
      state.tipoProyectos = val
    },
    _GET_TIPO_ESQUEMAS(state, val) {
      state.tipoEsquemas = val
    },
    _GET_TIPO_ESTADOS(state, val) {
      state.tipoEstados = val
    },
    _GET_DATOS_ADMINISTRATIVOS(state, val) {
      state.datosAdministrativos = val
    },
    _GET_CLIENTES(state, val) {
      state.clientes = val
    },
    _GET_TECNICOS(state, val) {
      state.tecnicos = val
    }
  },
  actions: {
    _tipoTareas({
      commit
    }) {
      axios.get("tipo-tareas").then((res) => {
        if (res.data.status_code == 200)
          commit("_GET_TIPO_TAREAS", res.data.data)
      });
    },
    _tipoActividades({
      commit
    }) {
      axios.get("tipo-actividades").then((res) => {
        if (res.data.status_code == 200)
          commit("_GET_TIPO_ACTIVIDADES", res.data.data)
      });
    },
    _tipoProyectos({
      commit
    }) {
      axios.get("tipo-proyectos").then((res) => {
        if (res.data.status_code == 200)
          commit("_GET_TIPO_PROYECTOS", res.data.data)
      });
    },
    _tipoEsquemas({
      commit
    }) {
      axios.get("tipo-esquemas").then((res) => {
        if (res.data.status_code == 200)
          commit("_GET_TIPO_ESQUEMAS", res.data.data)
      });
    },
    _tipoEstados({
      commit
    }) {
      axios.get("estados").then((res) => {
        if (res.data.status_code == 200) {
          // let array = res.data.data;
          // array.unshift({
          //   nombre: "Todos",
          //   id: null
          // });
          commit("_GET_TIPO_ESTADOS", res.data.data)
        }
      });
    },
    _datosAdministrativos({
      commit
    }) {
      axios.get("datos-administrativos").then((res) => {
        if (res.data.status_code == 200) {
          let array = res.data.data;
          array.unshift({
            nombre: "Todos",
            id: null
          });
          commit("_GET_DATOS_ADMINISTRATIVOS", res.data.data)


        }
      });
    },
    _clientes({
      commit
    }) {

      let params = {
        params: {
          all: true
        }
      }
      axios.get("clientes", params).then((res) => {
        if (res.data.status_code == 200) {
          let array = res.data.data;
          array.unshift({
            nombre_comercial: "Todos",
            id: null
          });
          commit("_GET_CLIENTES", array)
        }
      });
    },
    _tecnicos({
      commit
    }) {
      axios.get("tecnicos").then((res) => {
        if (res.data.status_code == 200) {
          let array = res.data.data;
          array.unshift({
            nombre: "Todos",
            id: null
          });
          commit("_GET_TECNICOS", res.data.data)
        }
      });
    }
  },
  modules: {}
})

export default store