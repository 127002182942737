<template>
  <v-container class="login-page">
    <v-row>
      <v-col
        cols="12"
        md="8"
        class="d-flex login-image"
        :class="{
          'order-2': $vuetify.breakpoint.smAndDown,
          'order-1': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="centered">
          <v-img src="../assets/logowhite.png"></v-img>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center login-form"
        :class="{
          'order-1': $vuetify.breakpoint.smAndDown,
          'order-2': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="login-box align-center ma-8">
          <p class="headline">Acceder</p>

          <v-form ref="form">
            <v-text-field
              v-model="email"
              label="Email"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Contraseña"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              required
              outlined
            ></v-text-field>

            <v-btn
              color="primary"
              class="px-3"
              x-large
              block
              depressed
              @click="_login()"
              :loading="loadingButton"
              :disabled="loadingButton"
            >
              Iniciar sesión
            </v-btn>

            <div class="mt-3">
              <a @click="_recuperarPassword()">Recuperar contraseña</a>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loadingButton: false,
      email: "",
      password: "",
      show1: false,
    };
  },

  methods: {
    async _login() {
      this.loadingButton = true;
      let snackbar = null;
      let params = {
        email: this.email,
        password: this.password,
      };
      axios
        .post(`login`, params)
        .then((auth) => {
          if (auth.data.status_code == 200) {
            let authObject = {
              token: auth.data.access_token,
              id: auth.data.user.id,
              nombre: auth.data.user.nombre,
              a: auth.data.user.es_admin,
            };

            this.$cookies.set("auth", authObject);
            this.$store.commit("_AUTH", authObject);

            snackbar = {
              status: true,
              message: "Has iniciado sesión correctamente.",
              type: "success",
            };

            this.$store.commit("_SNACKBAR", snackbar);

            if (auth.data.user.es_admin) {
              this.$router.push("/");
            } else {
              this.$router.push("/mis-proyectos");
            }

            this.loadingButton = false;
          } else {
            this._errorMessages(auth.data.data);
          }
        })
        .catch((err) => {
          this._errorMessages(err.response.data.errors);
        });
    },
    _errorMessages(errors) {
      let snackbar = {
        status: true,
        message: errors,
        type: "error",
      };

      this.$store.commit("_SNACKBAR", snackbar);
      this.loadingButton = false;
    },
    _recuperarPassword() {
      let step = 1;
      this.$store.commit("_IS_PASSWORD_FORM", step);
    },
  },
};
</script>

<style lang="scss">
.login-page,
.login-page .row {
  height: 100vh;
  margin: 0;
}

.login-page {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.login-image {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/home.jpg") no-repeat;
}

.login-box {
  width: 100%;
}
</style>
