var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"login-page"},[_c('v-row',[_c('v-col',{staticClass:"d-flex login-image",class:{
        'order-2': _vm.$vuetify.breakpoint.smAndDown,
        'order-1': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"centered"},[_c('v-img',{attrs:{"src":require("../assets/logowhite.png")}})],1)]),_c('v-col',{staticClass:"d-flex align-center login-form",class:{
        'order-1': _vm.$vuetify.breakpoint.smAndDown,
        'order-2': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"login-box align-center ma-8"},[_c('p',{staticClass:"headline"},[_vm._v("Acceder")]),_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":"Email","required":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Contraseña","type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"required":"","outlined":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"px-3",attrs:{"color":"primary","x-large":"","block":"","depressed":"","loading":_vm.loadingButton,"disabled":_vm.loadingButton},on:{"click":function($event){return _vm._login()}}},[_vm._v(" Iniciar sesión ")]),_c('div',{staticClass:"mt-3"},[_c('a',{on:{"click":function($event){return _vm._recuperarPassword()}}},[_vm._v("Recuperar contraseña")])])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }