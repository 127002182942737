<template>
  <v-app>
    <snackbar></snackbar>

    <v-dialog v-model="cerrandoSesion" max-width="250">
      <v-card class="pa-4 text-center">
        <v-card-text>
          <h3 class="mb-3">Cerrando sesión...</h3>
          <v-progress-circular
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- login page -->
    <template v-if="!_auth">
      <password-form v-if="_isPasswordForm"></password-form>
      <login-form v-else></login-form>
    </template>

    <!-- app -->
    <template v-else>
      <v-app-bar app color="primary" dark elevation="0">
        <v-app-bar-nav-icon
          @click.stop="sidebarMenu = !sidebarMenu"
        ></v-app-bar-nav-icon>

        <v-img max-width="110" src="./assets/logowhite.png"></v-img>

        <v-spacer></v-spacer>

        <v-btn v-if="_auth" text color="light" @click="_logout()">
          Cerrar sesión
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-model="sidebarMenu"
        app
        floating
        :permanent="sidebarMenu"
        :mini-variant.sync="mini"
        mini-variant-width="80"
      >
        <v-list dense color="primary" dark>
          <v-list-item>
            <v-list-item-action>
              <v-icon @click.stop="sidebarMenu = !sidebarMenu"
                >mdi-chevron-left</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <h3 class="font-weight">IKAL</h3>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item @click="toggleMini = !toggleMini">
          <v-list-item-avatar size="36">
            <v-icon color="black">
              mdi-account
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content style="font-size: 14px;" class="text-truncate">
            {{ _auth.nombre }}
          </v-list-item-content>
          <v-btn icon small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list v-if="_auth.a" dense subheader>
          <v-subheader>Administrador</v-subheader>
          <v-list-item
            v-for="item in itemsAdmin"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="_auth.a" dense subheader>
          <v-subheader>Controles</v-subheader>
          <v-list-item
            v-for="item in itemsControles"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="_auth" dense subheader>
          <v-subheader>Técnico</v-subheader>
          <v-list-item
            v-for="item in itemsTecnicos"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="_auth" dense subheader>
          <v-subheader>Crear copia de BD</v-subheader>
          <v-list-item link @click="_backup()">
            <v-list-item-icon>
              <v-icon color="primary">mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text"
                >Crear copia</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container class="px-4 py-2 fill-height" fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <v-footer class="py-3">
        <span class="ml-auto overline">IKAL &copy;2023</span>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import PasswordForm from "@/components/PasswordForm";
import axios from "axios";
import Snackbar from "@/components/Snackbar";
export default {
  name: "App",
  components: {
    Snackbar,
    LoginForm,
    PasswordForm,
  },
  data() {
    return {
      auth: false,
      sidebarMenu: true,
      toggleMini: false,
      cerrandoSesion: false,
      itemsAdmin: [
        { title: "Home", icon: "mdi-home-city", href: "/" },
        {
          title: "Técnicos",
          icon: "mdi-account-group",
          href: "/tecnicos",
        },
        {
          title: "Proyectos",
          icon: "mdi-folder-multiple",
          href: "/proyectos",
        },
        {
          title: "Clientes",
          icon: "mdi-account-multiple",
          href: "/clientes",
        },

        {
          title: "Ajustes generales",
          icon: "mdi-cog",
          href: "/datos",
        },
      ],
      itemsControles: [
        {
          title: "Conjunto de Proyectos",
          icon: "mdi-eye",
          href: "/controles/proyectos",
        },
        {
          title: "Técnico",
          icon: "mdi-eye",
          href: "/controles/tecnico",
        },
        {
          title: "Fuera de plazo",
          icon: "mdi-eye",
          href: "/controles/fuera-de-plazo",
        },
        {
          title: "Comparativa Técnicos",
          icon: "mdi-eye",
          href: "/controles/comparar-tecnicos",
        },
      ],
      itemsTecnicos: [
        {
          title: "Mis datos personales",
          icon: "mdi-account-edit",
          href: "/mis-datos",
        },
        {
          title: "Mis Proyectos",
          icon: "mdi-folder-edit",
          href: "/mis-proyectos",
        },
        {
          title: "Clientes",
          icon: "mdi-account-multiple",
          href: "/clientes",
        },
      ],
    };
  },
  computed: {
    _auth() {
      return this.$store.state.auth;
    },
    _isPasswordForm() {
      return this.$store.state.isPasswordForm;
    },
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  mounted() {
    if (this.$cookies.get("auth")) {
      this.auth = this.$cookies.get("auth");
      this.$store.commit("_AUTH", this.auth);
    }
  },
  watch: {
    _auth(val) {
      // init app
      if (val.id) {
        this.$store.dispatch("_tipoTareas");
        this.$store.dispatch("_tipoActividades");
        this.$store.dispatch("_tipoProyectos");
        this.$store.dispatch("_tipoEsquemas");
        this.$store.dispatch("_tipoEstados");
        this.$store.dispatch("_datosAdministrativos");
        this.$store.dispatch("_clientes");
        this.$store.dispatch("_tecnicos");
      }
    },
  },
  methods: {
    _logout() {
      this.cerrandoSesion = true;
      setTimeout(() => {
        this.cerrandoSesion = false;
        this.$cookies.remove("auth");
        this.$store.commit("_AUTH", false);
        this.$router.push("/");
      }, 1000);
    },
    _backup() {
      let snackbar = null;

      axios.post(`backup`).then((res) => {
        if (res.data.status_code == 200) {
          snackbar = {
            status: true,
            message: "La copia ha sido creada",
            type: "success",
          };
        } else {
          snackbar = {
            status: true,
            message: "Se ha producido un error al crear la copia",
            type: "error",
          };
        }

        this.$store.commit("_SNACKBAR", snackbar);
      });
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.v-main__wrap {
  background: rgba(black, 0.02);
}
</style>
