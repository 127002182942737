<template>
  <v-container class="login-page">
    <v-row>
      <v-col
        cols="12"
        md="8"
        class="d-flex login-image"
        :class="{
          'order-2': $vuetify.breakpoint.smAndDown,
          'order-1': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="centered">
          <v-img src="../assets/logowhite.png"></v-img>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center login-form"
        :class="{
          'order-1': $vuetify.breakpoint.smAndDown,
          'order-2': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="login-box align-center ma-8">
          <!-- step 1 -->
          <template v-if="_isPasswordForm == 1">
            <p class="headline">Recuperar contraseña</p>

            <v-form ref="form">
              <v-text-field
                v-model="usuario"
                label="Email o usuario"
                required
                outlined
              ></v-text-field>

              <v-btn
                color="primary"
                class="px-3"
                x-large
                block
                depressed
                @click="_recuperarPassword()"
                :loading="loadingButton"
                :disabled="loadingButton"
              >
                Recuperar contraseña
              </v-btn>
            </v-form>
          </template>

          <!-- step 2-->
          <template v-if="_isPasswordForm == 2">
            <p class="headline">Validar código</p>
            <p>Introduce aquí el código que te hemos enviado al email.</p>
            <v-form ref="form">
              <v-text-field
                v-model="codigo"
                label="Validar código"
                required
                outlined
              ></v-text-field>
              <v-btn
                color="primary"
                class="px-3"
                x-large
                block
                depressed
                @click="_validarCodigo()"
                :loading="loadingButton"
                :disabled="loadingButton"
              >
                Validar código
              </v-btn>
            </v-form>
          </template>

          <!-- step 3 -->
          <template v-if="_isPasswordForm == 3">
            <p class="headline">Restablecer contraseña</p>

            <v-form ref="form">
              <v-text-field
                v-model="newPassword"
                label="Nueva contraseña"
                :type="show1 ? 'text' : 'password'"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                required
                outlined
              ></v-text-field>

              <v-btn
                color="primary"
                class="px-3"
                x-large
                block
                depressed
                @click="_restablecerPassword()"
                :loading="loadingButton"
                :disabled="loadingButton"
              >
                Restablecer contraseña
              </v-btn>
            </v-form>
          </template>

          <div class="mt-3">
            <a @click="_inicio()">Ir al login</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loadingButton: false,
      usuario: "",
      codigo: null,
      newPassword: null,
      show1: false,
    };
  },
  computed: {
    _isPasswordForm() {
      return this.$store.state.isPasswordForm;
    },
  },
  methods: {
    async _recuperarPassword() {
      this.loadingButton = true;
      let snackbar = null;
      let params = {
        usuario: this.usuario,
      };
      axios
        .post(`recuperar-contraseña`, params)
        .then((auth) => {
          if (auth.data.status_code == 200) {
            snackbar = {
              status: true,
              message: "Te hemos enviado un código de verificación al email.",
              type: "success",
            };
            let step = 2;
            this.$store.commit("_IS_PASSWORD_FORM", step);

            this.$store.commit("_SNACKBAR", snackbar);
            this.loadingButton = false;
          } else {
            this._errorMessages(auth.data.data);
          }
        })
        .catch((err) => {
          this._errorMessages(err.response.data.errors);
        });
    },
    async _validarCodigo() {
      this.loadingButton = true;
      let snackbar = null;
      let params = {
        codigo: this.codigo,
      };
      axios
        .post(`validar-codigo`, params)
        .then((auth) => {
          if (auth.data.status_code == 200) {
            snackbar = {
              status: true,
              message: "El código es correcto. Introduce una nueva contraseña.",
              type: "success",
            };
            let step = 3;
            this.$store.commit("_IS_PASSWORD_FORM", step);

            this.$store.commit("_SNACKBAR", snackbar);
            this.loadingButton = false;
          } else {
            this._errorMessages(auth.data.data);
          }
        })
        .catch((err) => {
          this._errorMessages(err.response.data.errors);
        });
    },
    async _restablecerPassword() {
      this.loadingButton = true;
      let snackbar = null;
      let params = {
        password: this.newPassword,
        codigo: this.codigo,
      };
      axios
        .post(`restablecer-contraseña`, params)
        .then((auth) => {
          if (auth.data.status_code == 200) {
            snackbar = {
              status: true,
              message: "La contraseña ha sido restablecida.",
              type: "success",
            };
            let step = null;
            this.$store.commit("_IS_PASSWORD_FORM", step);

            this.$store.commit("_SNACKBAR", snackbar);
            this.loadingButton = false;
          } else {
            this._errorMessages(auth.data.data);
          }
        })
        .catch((err) => {
          this._errorMessages(err.response.data.errors);
        });
    },

    _errorMessages(errors) {
      let snackbar = {
        status: true,
        message: errors,
        type: "error",
      };

      this.$store.commit("_SNACKBAR", snackbar);
      this.loadingButton = false;
    },
    _inicio() {
      let step = null;
      this.$store.commit("_IS_PASSWORD_FORM", step);
    },
  },
};
</script>

<style lang="scss">
.login-page,
.login-page .row {
  height: 100vh;
  margin: 0;
}

.login-page {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.login-image {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/home.jpg") no-repeat;
}

.login-box {
  width: 100%;
}
</style>
