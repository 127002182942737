<template>
  <v-snackbar
    v-model="snackbar"
    :color="_snackbar.type"
    fixed
    top
    right
    timeout="4000"
  >
    <ul v-if="typeof _snackbar.message === 'object'" class="errors">
      <li v-for="(item, i) in _snackbar.message" :key="i">
        {{ item[0] }}
      </li>
    </ul>

    <div v-else>{{ _snackbar.message }}</div>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    multiLine: true,
    snackbar: false,
    text: `I'm a multi-line snackbar.`,
  }),
  computed: {
    ...mapState({
      _snackbar: "snackbar",
    }),
  },
  watch: {
    _snackbar(val) {
      if (val.status) this.snackbar = true;
    },
  },
  methods: {
    _close() {
      this.snackbar = false;
      this.$store.commit("_SNACKBAR", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.errors {
  list-style: square;
}
</style>
