import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

const routes = [{
    path: "*",
    name: 'Error404',
    component: () => import( /* webpackChunkName: "about" */ '../views/404.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "about" */ '../views/Proyectos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import( /* webpackChunkName: "about" */ '../views/Clientes.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/editar/cliente',
    name: 'ClientesEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ClientesEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/editar/cliente/:id',
    name: 'ClientesEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ClientesEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/datos',
    name: 'Datos',
    component: () => import( /* webpackChunkName: "about" */ '../views/Datos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editar/datos/:slug/:id',
    name: 'DatosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/DatosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editar/datos/:slug',
    name: 'DatosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/DatosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/tecnicos',
    name: 'Tecnicos',
    component: () => import( /* webpackChunkName: "about" */ '../views/Tecnicos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/mis-datos',
    name: 'UsuarioEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/UsuarioEditar.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editar/tecnico',
    name: 'TecnicosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/TecnicosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editar/tecnico/:id',
    name: 'TecnicosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/TecnicosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/tecnicos/:id',
    name: 'TecnicosDetalle',
    component: () => import( /* webpackChunkName: "about" */ '../views/TecnicosDetalle.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/proyectos',
    name: 'Proyectos',
    component: () => import( /* webpackChunkName: "about" */ '../views/Proyectos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/proyectos/:id',
    name: 'ProyectosDetalle',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProyectosDetalle.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/mis-proyectos',
    name: 'ProyectosTecnico',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProyectosTecnico.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/mis-proyectos/planificar/:id',
    name: 'ProyectosPlanificar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProyectosPlanificar.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/editar/proyecto',
    name: 'ProyectosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProyectosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editar/proyecto/:id',
    name: 'ProyectosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProyectosEditar.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editar/actividad/:id_proyecto/:id_actividad',
    name: 'ActividadesEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/ActividadesEditar.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/editar/gasto/:id_proyecto/:id_gasto',
    name: 'GastosEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/GastosEditar.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/editar/nota/:id_proyecto/:id_nota',
    name: 'NotasEditar',
    component: () => import( /* webpackChunkName: "about" */ '../views/NotasEditar.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/controles/comparar-tecnicos',
    name: 'Controles',
    component: () => import( /* webpackChunkName: "about" */ '../views/controles/CompararTecnicos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/controles/proyectos',
    name: 'Controles',
    component: () => import( /* webpackChunkName: "about" */ '../views/controles/Proyectos.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/controles/fuera-de-plazo',
    name: 'Controles',
    component: () => import( /* webpackChunkName: "about" */ '../views/controles/FueraDePlazo.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/controles/tecnico',
    name: 'Controles',
    component: () => import( /* webpackChunkName: "about" */ '../views/controles/Tecnico.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let auth = Vue.$cookies.get("auth")
    if (!auth) {
      store.commit("_AUTH", false);
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    let auth = Vue.$cookies.get("auth")
    if (auth) {
      if (!auth.a) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    }
  } else {
    next() // make sure to always call next()!
  }
});

Vue.use(VueRouter)

export default router